
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  utils,
  writeFile
} from 'xlsx'
import SearchService from '../services/search-service'
import Status from '@/components/Status/index.vue'
import {
  Printd
} from 'printd'

  @Component({
    name: 'YardReport',
    components: {
      Status
    }
  })

export default class YardReport extends Vue {
    protected showComponent = false
    protected isLoader = false
    protected isResult = false
    protected jobs: any = []
    protected statuses: any = ['pending', 'commenced', 'attend', 'loaded', 'dispatch', 'received', 'completed']
    protected selected: any = {
      from_date: null,
      to_date: null,
      status: null
    }

    protected document: any = null
    protected styles = ['https://api.smc-spl-application.com/print.css']

    protected fields = [{
      key: 'created_date',
      label: 'Date',
      sortable: true,
      thStyle: {
        width: '5%'
      }
    },
    {
      key: 'job_number',
      label: 'Job Number',
      sortable: true,
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'vessel_name',
      label: 'Vessel',
      sortable: true,
      thStyle: {
        width: '12%'
      }
    },
    {
      key: 'vessel_arrival_date',
      label: 'Arrival Date',
      sortable: true,
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'voyage_number',
      label: 'Voyage',
      sortable: true,
      thStyle: {
        width: '5%'
      }
    },
    {
      key: 'port_name',
      label: 'Port/Terminal',
      sortable: true,
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'lines',
      label: 'Services',
      thStyle: {
        width: '12%'
      },
      sortable: true
    },
    {
      key: 'status',
      label: 'Status',
      class: 'text-center',
      sortable: true,
      thStyle: {
        width: '8%'
      }
    }
    ]

    public show () {
      this.showComponent = true
      this.document = new Printd()
    }

    protected hide () {
      this.clear()
      this.showComponent = false
    }

    get rows () {
      return this.jobs.length
    }

    protected runReport () {
      this.isResult = true
      // this.isLoader = true
    }

    protected printReport () {
      this.$nextTick(() => {
        this.document.print(document.getElementById('printQuickReport'), this.styles)
      })
    }

    protected downloadReport () {
      const jobs = this.jobs.map(job => ({
        Date: job.created_date,
        'Job Number': job.job_number,
        Vessel: job.vessel_name,
        'Arrival Date': job.vessel_arrival_date,
        Voyage: job.voyage_number,
        'Port/Terminal': job.port_name + '/' + job.terminal_name,
        Services: job.lines.map(function (line) {
          return line.service_type + ' - ' + line.estimated_volume + ' ' + line.unit_of_measurement
        }).join(', '),
        Status: job.status
      }))

      const ws = utils.json_to_sheet(jobs)
      const wb = utils.book_new()
      utils.book_append_sheet(wb, ws, 'Data')
      writeFile(wb, 'Job Report.xlsx')
    }

    public clear () {
      this.reset(this.selected)
      this.jobs = []
      this.isResult = false
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

